import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Text, Flex, Col, Button } from '../Ui';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { ReactComponent as Face } from '../../assets/images/facebook.svg';
import { ReactComponent as Insta } from '../../assets/images/instagram.svg';
import { ReactComponent as In } from '../../assets/images/linkedin.svg';
import { ReactComponent as Tik } from '../../assets/images/tiktok.svg';
import { ReactComponent as Twit } from '../../assets/images/twitter.svg';
import { ReactComponent as Yout } from '../../assets/images/youtube.svg';
import { ReactComponent as Gmail } from '../../assets/images/gmail.svg';
import { ReactComponent as Phon } from '../../assets/images/phon.svg';
import { ReactComponent as ToUp } from '../../assets/images/toUp.svg';
import { Link } from 'react-router-dom';
import img from '../../assets/images/bggame.jpg';
import { respondTo } from '../../theme/mixin';

const FooterContainer = styled.footer`
  margin-top: 30px;
  border-radius: ${({ theme }) => theme.border_radius_generale_grande}
    ${({ theme }) => theme.border_radius_generale_grande} 0 0;
  overflow: hidden;
  position: relative;
`;

const TopBar = styled.div`
  padding: 5px 10px;

  ${respondTo.md`
  padding: 10px 40px;
  `}
  .supportTime {
    border-top: 1px solid #bebebe;
    border-bottom: 1px solid #bebebe;
    padding: 10px;
    text-align: center;
    margin: 15px 0 40px 0;
  }
  .content > * {
    font-size: 12px;
    font-weight: bold;
    color: #000;
  }
  .flex {
    margin-top: 20px;
  }
  .col2 {
    padding: 5px 0;
    border-right: none;
    ${respondTo.md`
      border-right:  ${({ theme, emailExists }) => (emailExists ? ' 1px solid #bebebe' : '')}
  `};
  }
  .col1 {
    padding: 5px 0;
  }
`;

const CustomFlex = styled(Flex)`
  svg {
    margin-right: 10px;
    font-size: 40px;
    color: ${props => props.color};
    width: ${({ gmail }) => (gmail ? '35px' : '22px')};
    height: ${({ gmail }) => !gmail && '22px'};
    ${respondTo.md`
    height: initial;
    width: ${({ gmail }) => (gmail ? '55px' : '30px')};
  `}
  }
  p > p {
    font-size: 18px !important;
  }

  .textSize {
    a {
      font-size: 14px;
    }
  }
  ${respondTo.md`
     margin-bottom: 0;
    
     `}
`;

const BottomBar = styled.div`
  background-color: ${({ theme }) => theme.bg_footer_basso};
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  .links {
    color: ${({ linkColor }) => linkColor};

    a {
      color: ${({ linkColor }) => linkColor};
    }
  }
  svg {
    width: 20px;
    margin-right: 10px;
  }
  .btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    svg {
      width: 30px;
    }
  }
  ${respondTo.sm`
    svg {
    width: 30px;
    margin-right: 20px; 
  }
    `};
`;
const Social = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  img {
    width: 40px;
  }
  svg {
    width: 40px;
    margin: 0;
  }
`;

const IconUp = styled(ToUp)`
  cursor: pointer;
  background-color: ${props => props.theme.bg_bottoni};
  color: ${props => props.theme.colore_testo_bottoni};
  padding: 3px;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  margin-left: auto;
  display: block;
  ${respondTo.sm`
 margin-left: 0;
  `};
`;

const MiddleBar = styled.div`
  position: relative;
  background-image: ${({ bg }) => 'url(' + bg + ')'};
  background-size: cover;
  background-repeat: no-repeat;

  img {
    display: block;
  }
  .content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin-top: 40px;
    }
  }
`;

const Footer = props => {
  const footercontent = useSelector(state => state.cms.footer);
  const fundraising_institution_count = useSelector(
    state => state.app.config.fundraising_institution_count,
  );

  const footerEmail = footercontent[0].contents.find(el => el.name === 'footer_email');
  const footerTel = footercontent[0].contents.find(el => el.name === 'footer_tel');
  const footerDetails1 = footercontent[0].contents.find(el => el.name === 'footer_details1');
  const footerContent = footercontent[0].contents.find(el => el.name === 'footer_content');
  const footerLinks = footercontent[0].contents.find(el => el.name === 'footer_links');
  const footerLogo = footercontent[0].contents.find(el => el.name === 'footer_logo');
  const footerPartners = footercontent[0].contents.find(el => el.name === 'footer_partners');
  const footerMiddleText = footercontent[0].contents.find(el => el.name === 'footer_middle_text');
  const footerTitle = footercontent[0].contents.find(el => el.name === 'footer_title');
  const footerBottomText = footercontent[0].contents.find(el => el.name === 'footer_bottom_text');
  const footerOrario = footercontent[0].contents.find(el => el.name === 'footer_orario');
  const { footerSettings } = useSelector(
    state => JSON.parse(state.app.config.settings.option) || {},
  );

  const socials = {
    facebook: <Face />,
    instagram: <Insta />,
    linkedin: <In />,
    tiktok: <Tik />,
    youtube: <Yout />,
    twitter: <Twit />,
  };
  return (
    <Wrapper>
      <FooterContainer>
        <TopBar
          emailExists={footerEmail?.i18l.content && footerEmail?.i18l.content !== '<p>&nbsp;</p>'}
        >
          {footerTitle?.i18l.content && (
            <Text align='center' color={footerTitle?.text_color}>
              {footerTitle?.i18l.content}
            </Text>
          )}
          <Flex justify='space-between' align='flex-end' className='flex'>
            {footerTel?.i18l.content && Boolean(footerTel?.i18l.content !== '<p>&nbsp;</p>') && (
              <Col padding='0' className='col2' width={50}>
                <Flex direction='column' align='center' gap='10px'>
                  {footerTel?.img ? <img src={footerTel?.img} alt='' /> : <Phon phon />}{' '}
                  <Text as='p' size={18} primary>
                    {footerTel?.i18l.title}
                  </Text>
                  <Text as='p' size={14} className='content'>
                    {footerTel?.i18l.content}
                  </Text>
                </Flex>
              </Col>
            )}
            {footerEmail?.i18l.content && footerEmail?.i18l.content !== '<p>&nbsp;</p>' && (
              <Col padding='0' className='col1' width={50}>
                <Flex direction='column' align='center' gap='10px'>
                  {footerEmail?.img ? <img src={footerEmail?.img} alt='' /> : <Gmail />}
                  <Text as='p' size={18} primary>
                    {footerEmail?.i18l.title}
                  </Text>
                  <Text as='p' size={14} className='content'>
                    {footerEmail?.i18l.content}
                  </Text>
                </Flex>
              </Col>
            )}
          </Flex>
          {footerOrario?.i18l.content && (
            <Text className='supportTime' size={14}>
              {footerOrario?.i18l.content}
            </Text>
          )}
        </TopBar>
        <MiddleBar bg={footerContent?.img}>
          <div className='content'>
            {fundraising_institution_count && (
              <Text
                bold
                size={40}
                as='strong'
                style={{ textAlign: 'center' }}
                color={footerContent?.text_color}
              >
                {fundraising_institution_count}
              </Text>
            )}
            {footerContent?.i18l.content && (
              <Text as='p' style={{ textAlign: 'center' }} color={footerContent?.text_color}>
                {footerContent?.i18l.content}
              </Text>
            )}
            {footerContent?.show_cta && (
              <Button active>
                <Link to={footerContent?.cta_url}>{footerContent?.cta_label}</Link>
              </Button>
            )}
          </div>
        </MiddleBar>
        <BottomBar linkColor={footerContent?.text_color}>
          {footerLinks?.i18l.content && (
            <Text as='p' style={{ textAlign: 'center' }} className='links'>
              {footerLinks?.i18l.content}
            </Text>
          )}
          <Flex justify='space-between' style={{ margin: '15px 0' }} align='center'>
            <Col style={{ textAlign: 'center' }} width={20}>
              {footerLogo?.img && <img src={footerLogo?.img} alt='' />}
            </Col>
            <Col style={{ textAlign: 'center' }} width={60}>
              {footerMiddleText?.i18l.content && (
                <Text size={12} color={footerMiddleText?.text_color}>
                  {footerMiddleText?.i18l.content}
                </Text>
              )}
            </Col>

            <Col style={{ textAlign: 'center' }} width={20}>
              {footerPartners?.img && <img src={footerPartners?.img} alt='' />}
            </Col>
          </Flex>
          {footerBottomText?.i18l.content && (
            <Text bold color='#fff' size={14} align='center'>
              {footerBottomText?.i18l.content}
            </Text>
          )}
          <div className='btn'>
            <IconUp onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
          </div>
        </BottomBar>
      </FooterContainer>
    </Wrapper>
  );
};

Footer.defaultProps = {
  content: '',
};

Footer.propTypes = {
  footercontent: PropTypes.array,
};

export default Footer;
