import React from 'react';
import { Text, Wrapper } from '../../components/Ui';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

function Participate() {
  const participate = useSelector(state => state.cms.howToParticipate);

  const participate_content = participate[0].contents.find(el => el.name === 'participate');

  return (
    <Wrapper>
      {(participate_content?.i18l.title || participate_content?.img) && (
        <ParticipateTop>
          {participate_content?.i18l.title && (
            <Text bold primary align='center'>
              {participate_content?.i18l.title}
            </Text>
          )}
          {participate_content?.img && <img src={participate_content?.img} alt='' />}
        </ParticipateTop>
      )}
      {participate_content?.i18l.content && (
        <ParticipateBottom>
          <Text as='p' style={{ padding: '0 15px' }}>
            {participate_content?.i18l.content}
          </Text>
        </ParticipateBottom>
      )}
    </Wrapper>
  );
}
const ParticipateTop = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  img {
    display: block;
    margin-top: 10px;
    width: 100%;
  }
`;
const ParticipateBottom = styled.div``;

export default Participate;
