import Fuse from 'fuse.js';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactShadowScroll from 'react-shadow-scroll';
import styled, { useTheme } from 'styled-components';
import { closeOrderModal, openOrderModal } from '../../store/actions/products';
import { respondTo } from '../../theme/mixin';
import { numberWithDots } from '../../utils/utils';
import Fade from '../Fade';
import { Box, Button, Col, Flex, Loader, Modal, Text } from '../Ui';
import Input from '../Ui/Input/InputField/InputField';
import Recensione from '../recensione';
import OrderDetails from './OrderDetails/OrderDetails';
const CustomSearch = styled.div`
  width: 250px;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  & > div {
    padding: 0;
    border: none;
  }
  input {
    height: 40px;
  }
  svg {
    height: 40px;
  }
`;
const CustomBox = styled(Box)`
  width: 100%;
  .link {
    display: block;

    font-weight: bold;
    margin-top: 10px;
  }
  ${respondTo.md78`
    
    `};
`;
function Ordini() {
  const style = { paddingTop: '50px' };
  const { orders } = useSelector(state => state.user.user);
  const {
    product,
    orderModal,
    loading: singleProductLoading,
  } = useSelector(state => state.product);
  const theme = useTheme();
  const [order, setOrder] = useState(null);

  //const [singleProduct, setSingleProduct] = useState(null);

  const [date, setDate] = useState('');
  const [search, setSearch] = useState('');
  const [slideRows, setSlideRows] = useState([]);
  const [isReviews, setIsReviews] = useState(false);
  const dispatch = useDispatch();
  const { id: userId, score } = useSelector(state => state.user.user.userinfo || {});
  useEffect(() => {
    setSlideRows(orders);
  }, [orders]);

  useEffect(() => {
    const fuse = new Fuse(slideRows, {
      keys: ['products.points', 'products.title', 'order_number', 'status'],
      useExtendedSearch: true,
      includeMatches: true,
    });
    const result = fuse.search(search).map(({ item }) => item);

    if (search.length > 0 && result.length > 0 && slideRows.length > 0) {
      setSlideRows(result);
    } else {
      setSlideRows(orders);
    }
  }, [search]);
  const handlerModal = (id, catalog_id) => {
    //dispatch(getSingleProduct(id, catalog_id));

    dispatch(openOrderModal(order?.products[0]));
  };

  return (
    <>
      <Fade>
        <CustomSearch>
          <Input
            icon='search'
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder='Ricerca'
          />
        </CustomSearch>
        {slideRows?.length > 0 &&
          slideRows?.map(order => (
            <CustomBox padding={0} margin='0 0 20px 0'>
              <Flex>
                <Col width={22} alignResp='center' align='left'>
                  <img src={order?.products?.[0]?.images?.[0]?.small} alt='' />
                </Col>
                <Col width={25} style={style}>
                  <Text as='p' size={14} capit bold text_box>
                    {order?.products?.[0]?.title}
                  </Text>
                  <Flex style={{ margin: '10px 0' }} align='center'>
                    <Text
                      bold
                      size={14}
                      capit
                      text_box={order.status !== 'Failed'}
                      text_red={order.status === 'Failed'}
                    >
                      stato:
                    </Text>
                    <Text
                      margin='0 0 0 5px'
                      capit
                      text_red={order.status === 'Failed'}
                      text_box={order.status !== 'Failed'}
                    >
                      {order.status}
                    </Text>
                  </Flex>
                  <Flex wrap='nowrap' align='center'>
                    <Text bold size={14} capit text_box>
                      Ordine effettuato il:
                    </Text>
                    <Text margin='0 0 0 5px' text_box>
                      {moment(order?.date).format('DD/MM/YYYY')}
                    </Text>
                  </Flex>
                  <Flex wrap='nowrap' style={{ marginTop: '10px' }} align='center'>
                    <Text bold size={14} capit text_box>
                      numero:
                    </Text>
                    <Text margin='0 0 0 5px' text_box>
                      {order?.order_number}
                    </Text>
                  </Flex>
                  {order.tracking_url && (
                    <a href={order.tracking_url} target='_blank' rel='noopener noreferrer'>
                      <Text
                        bold
                        size={14}
                        capit
                        text_box
                        style={{ textDecoration: 'underline' }}
                        className='mt-10'
                      >
                        traccia il tuo ordine
                      </Text>
                    </a>
                  )}
                  {order.code && (
                    <Flex wrap='nowrap' style={{ marginTop: '10px' }} align='center'>
                      <Text bold size={14} capit text_box>
                        codice:
                      </Text>
                      <Text margin='0 0 0 5px' text_box>
                        {order?.code}
                      </Text>
                    </Flex>
                  )}

                  {order.products?.[0]?.voucher?.startsWith('http://') ||
                  order.products?.[0]?.voucher?.startsWith('https://') ? (
                    <a href={order.products[0]?.voucher} className='link'>
                      Codice vinto
                    </a>
                  ) : (
                    order.products?.[0]?.voucher && (
                      <Flex wrap='nowrap' style={{ marginTop: '10px' }} align='center'>
                        <Text bold size={14} capit text_box>
                          Codice vinto:
                        </Text>
                        <Text margin='0 0 0 5px' text_box>
                          {order.products?.[0]?.voucher}
                        </Text>
                      </Flex>
                    )
                  )}
                  {order.products?.[0].quantity && (
                    <Flex wrap='nowrap' style={{ marginTop: '10px' }} align='center'>
                      <Text bold size={14} capit text_box>
                        Quantità:
                      </Text>
                      <Text margin='0 0 0 5px' text_box>
                        {order.products?.[0].order_quantity}
                      </Text>
                    </Flex>
                  )}
                </Col>
                <Col width={25} style={style}>
                  <Text upper text_box>
                    PUNTI UTILIZZATI
                  </Text>
                  <Text type='bigTitle' size={16} margin='10px 0 0 43px' bold text_box>
                    {numberWithDots(order?.products?.[0]?.order_points)}
                  </Text>
                </Col>
                <Col width={25} style={style}>
                  <Button
                    size={14}
                    padding='0 10px 0 15px'
                    style={{
                      backgroundImage: 'none',

                      marginBottom: '20px',
                    }}
                    active
                    fullwidth
                    onClick={() => {
                      setIsReviews(false);
                      handlerModal(order.products?.[0]?.id, order.products?.[0]?.catalog_id);
                      setDate(order.date);
                      // setSingleProduct(order.products[0]);
                      setOrder(order);
                    }}
                  >
                    VEDI IL TUO ORDINE
                  </Button>
                  {!order.products?.[0]?.reviews?.find(elem => elem.player_id === userId) && (
                    <Button
                      size={14}
                      padding='0 10px 0 15px'
                      style={{ backgroundImage: 'none' }}
                      secondary
                      fullwidth
                      onClick={() => {
                        setIsReviews(true);
                        handlerModal(order.products?.[0]?.id, order.products?.[0]?.catalog_id);
                      }}
                    >
                      SCRIVI UNA RECENSIONE
                    </Button>
                  )}
                </Col>
              </Flex>
            </CustomBox>
          ))}
      </Fade>
      {/* <Modal show={isMyOrder} close={() => setIsMyOrder(false)}>
        orderNumber: 'YS22880771', customerId: 1690383, lastUpdate: "2022-03-07T12:44:44.839Z"
        orderDate: "2022-03-07T12:44:44.657Z" orderNumber: "YS22880771" paymentMethodCode: "BB30G"
      </Modal>
      <Modal show={isOrder} close={() => setIsOrder(false)}>
        <Recensione />
      </Modal> */}
      <Modal
        show={orderModal.isOpen}
        close={() => dispatch(closeOrderModal())}
        isFixed={!isReviews}
      >
        {singleProductLoading && !isReviews ? (
          <Loader show={singleProductLoading} />
        ) : isReviews ? (
          <Recensione product={order?.products[0]} />
        ) : (
          <ReactShadowScroll
            style={{ width: '100%', maxHeight: '80vh' }}
            isShadow={false}
            scrollColor={'#cccccc'}
            scrollColorHover='gray'
            styleSubcontainer={{ overflowX: 'hidden' }}
          >
            <OrderDetails inModal={true} setIsReviews={setIsReviews} order={order} date={date} />
          </ReactShadowScroll>
        )}
      </Modal>
    </>
  );
}

export default Ordini;
