import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkoutForm } from '../../formsConfig/formsConfig';
import useForm from '../../hooks/useForm';
import { Box, Button, Input, Loader, Modal, Text } from '../Ui';
import { checkout } from '../../store/actions/products';
import customAxiosNm from '../../config/axios-refresh-token';

function Checkout(props) {
  const { userinfo } = useSelector(state => state.user.user);
  const { newOrder } = useSelector(state => state.product);
  const has_fee = useSelector(state => state.app?.config?.has_fee);
  const [loading, setLoading] = useState(false);
  const [isMinimal, setIsMinimal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    let endpoint = `/catalog/checkoutdetails`;
    setLoading(true);
    customAxiosNm
      .get(endpoint)
      .then(result => {
        setIsMinimal(result.data.every(item => item.form_minimal));
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, [userinfo]);
  const checkoutform = checkoutForm(userinfo || '', isMinimal);

  /*   if (isMinimal) {
    delete checkoutform['city'];
    delete checkoutform['address1'];
    delete checkoutform['country'];
    delete checkoutform['district'];
    delete checkoutform['phone'];
    delete checkoutform['zipcode'];
  } */

  const checkoutformUpdate = () => {
    const data = { form_minimal: isMinimal };
    for (const property in formData) {
      if (property === 'address1') {
        data['address'] = formData[property].value;
      } else if (property === 'district') {
        data['province'] = formData[property].value;
      } else if (property === 'update_user') {
        data[property] = formData[property].value ? true : false;
      } else {
        data[property] = formData[property].value;
      }
    }
    dispatch(checkout({ ...data })).then(result => {
      localStorage.setItem('checkoutDtata', JSON.stringify(result?.data));
      props.nextStep(true);
    });
  };

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    startDate,
    datePickerHandler,
    showError,
    countries,
    districts,
    cities,
    zipcode,
  } = useForm(checkoutformUpdate, checkoutform, [], false, false, isMinimal, userinfo);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        error={inp.errorMessage}
        showError={showError}
        inModal={inp.inModal}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        changedDatePicker={datePickerHandler}
        label={inp.label}
        startdate={startDate}
        isvalid={inp.valid}
        readonly={inp.readonly}
        istouched={inp.validation?.touched}
        firstSubmit={firstSubmit}
        addressTypes={[]}
        radioInputs={inp.inputs}
        countries={[...countries, { id: 2, name: 'San Marino', value: 'San Marino' }]}
        districts={districts}
        cities={cities}
        zipcode={zipcode}
        form={formData}
        {...inp}
      />
    );
  });

  return userinfo ? (
    <>
      <Box padding='25px' margin='0 auto' width='650px'>
        {loading ? (
          <Loader show={loading} />
        ) : (
          <form>
            {isMinimal ? (
              <>
                {inputs.slice(0, 2)}
                {inputs.slice(8)}
              </>
            ) : (
              inputs
            )}

            <Button
              loading={newOrder.loading}
              active
              white
              onClick={e => {
                e.preventDefault();
                has_fee ? formSubmitHandler(e) : setShowConfirmModal(true);
              }}
            >
              Richiedi premio
            </Button>
          </form>
        )}
      </Box>

      <Modal show={showConfirmModal} close={() => setShowConfirmModal(false)} alert>
        <Text style={{ marginBottom: 20 }}>
          Sei sicuro di voler confermare la tua richiesta? La richiesta del premio non può essere
          annullata
        </Text>
        <Button
          loading={newOrder.loading}
          active
          white
          onClick={() => {
            formSubmitHandler();
            setShowConfirmModal(false);
          }}
        >
          Conferma
        </Button>
      </Modal>
    </>
  ) : null;
}

export default Checkout;
