import React, { useEffect, useState } from 'react';
import StarRating from './StarRating';
import styled, { css } from 'styled-components/macro';
import { Flex, Input, MaskImg, Text } from '../Ui';
import Button from '../Ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineHeart, AiOutlineShoppingCart, AiOutlineFileSearch } from 'react-icons/ai';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { respondTo } from '../../theme/mixin';
import { ReactComponent as RemoveWishlistIcon } from '../../assets/images/removeWishlistIcon.svg';
import {
  addToWishlist,
  removeFromWishlist,
  addToCart,
  removeFromCart,
} from '../../store/actions/wishlist';
import { ReactComponent as Arrow } from '../../assets/images/arrowDown.svg';
import { getSingleProduct, openOrderModal, closeOrderModal } from '../../store/actions/products';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { numberWithDots } from '../../utils/utils';
import useWishlist from '../../hooks/useWishlist';
import useCart from '../../hooks/useCart';
import { productVariantsForm } from '../../formsConfig/formsConfig';
import useForm from '../../hooks/useForm';

const Container = styled.div`
  display: flex !important;
  flex-direction: column;
  //width: 49%;
  position: relative;
  border: ${({ border, theme, show_border }) =>
    show_border ? (border ? '1px solid ' + theme.colore_bordo_box : 'none') : 'transparent'};
  box-shadow: ${({ products_shadow }) => products_shadow};
  border-radius: ${({ theme }) => theme.border_radius_generale_grande};
  padding: 7px;
  margin-bottom: ${({ bottom }) => bottom}px;
  background-color: ${({ theme }) => theme.bg_box};
  ${({ horiz }) =>
    horiz &&
    css`
      display: flex;
      justify-content: space-between;
      padding: 0;
    `};
  ${({ horiz, inModal }) =>
    !horiz &&
    !inModal &&
    css`
      ${respondTo.md60`
      //width:49%;
  `};
      ${respondTo.md78`
     // width:32%;
  `};
    `}
  .textDesc {
    font-size: 7px;

    ${respondTo.sm`
       font-size: 12px;
  `};
  }
  .textMobile {
    display: none;
    ${respondTo.md`
      display: block;
  `};
  }
  .textTitle {
    font-size: 10px;
    ${respondTo.sm`
       font-size: 13px;
  `};
  }
`;

const Img = styled.div`
  display: flex;
  justify-content: center;
  height: 157px;
  overflow: hidden;
  cursor: pointer;
  img {
    object-fit: cover;
  }
  ${({ horiz }) =>
    horiz &&
    css`
      align-items: flex-start;
      width: 33%;
    `};
`;
const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  margin-top: 15px;
  & > * + * {
    margin-top: 15px;
  }
  ${({ horiz }) =>
    horiz &&
    css`
      width: 60%;
      & > :first-child {
        text-align: left;
      }
    `};
  ${({ inModal }) =>
    inModal &&
    css`
      & > * {
        text-align: left;
      }
    `};
  .recentFlex {
    justify-content: center;
    display: none;
    ${respondTo.md`
   display: flex;
   justify-content: space-between;
  `};
  }
  .descMobile {
    display: none;
    svg {
      color: ${({ theme }) => theme.colore_testo_box};
    }
    ${respondTo.md`
        background-color: ${({ theme }) => theme.bg_bottone_descrizione};
    border-color: ${({ theme }) => theme.bordo_bottone_descrizione};
      display: flex;
  `};
  }
  .recentText {
    margin: 0 auto;
    margin-top: 10px;
    ${respondTo.sm`
    margin:0;
      margin-left: auto;
       cursor: pointer;
       margin-top: 0;
  `};
  }
  .btns {
    border: 1px solid transparent;
    width: 48%;
    padding: 0;
    justify-content: center;
    svg {
      margin-left: 0;
      color: ${({ theme }) => theme.colore_testo_box};

      ${respondTo.md`
        margin-left:auto;
      `};
    }

    ${respondTo.md`
      justify-content: space-between;
      padding:0 10px;
      width:100%;
      border:${({ theme, show_border }) =>
        show_border ? '1px solid ' + theme.colore_bordo_box : 'none'};
    `};
  }

  .btnMobile {
    order: 1;
    ${respondTo.md`
      order:initial;
    `}
  }

  .flexBtns {
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    flex-wrap: nowrap;

    ${respondTo.md`
      flex-direction: column;
      justify-content: center;
    `};
  }
`;

const AddToCart = styled(MaskImg)`
  width: 35px;
  height: 35px;
  margin: 0;
`;
const AddToWishlist = styled(AddToCart)``;

function Product({
  setpuntiAbbastanza,
  inLine,
  inSlider,
  product,
  inModal,
  horiz,
  setIsReviews,
  date,
  status,
  inApp,
  ...restProps
}) {
  const {
    id,
    catalog_id,
    title,
    points,
    fee,
    images = [],
    rating,
    reviews,
    macroCategory_description,
    brand_description,
    is_salabam,
    hierarchy,
    children,
  } = product || {};

  const dispatch = useDispatch();
  const isWishlist = useWishlist();
  const isCart = useCart();
  const wishlist = useSelector(state => state.user.user.wishlist);
  const cart = useSelector(state => state.user.user.cart);
  const show_product_borders = useSelector(state => state.app.config.show_product_borders);
  const products_shadow = useSelector(state => state.app.config.products_shadow);
  const pointsLabel = useSelector(state => state.app.config.points_label);
  const has_fee = useSelector(state => state.app.config.has_fee);

  const { id: userId, score } = useSelector(state => state.user.user.userinfo || {});
  const option = useSelector(state => JSON.parse(state.app.config.settings.option) || {});
  const [isLoadingCart, setIsLoadingCart] = useState(false);
  const [isLoadingWish, setIsLoadingWish] = useState(false);

  const [inWishlist, setInWishlist] = useState(false);
  const [inCart, setInCart] = useState(false);
  const [numRating, setNumRating] = useState(0);
  const history = useHistory();
  const pathName = window.location.pathname;

  const addToWishlistHandler = async productId => {
    setIsLoadingWish(true);
    dispatch(closeOrderModal());
    await dispatch(addToWishlist(productId, catalog_id));
    setIsLoadingWish(false);
  };

  const removeFromWishlistHandler = async productId => {
    setIsLoadingWish(true);
    pathName === '/wishlist' && dispatch(closeOrderModal());
    await dispatch(removeFromWishlist(productId, catalog_id));

    setIsLoadingWish(false);
  };
  const moveToCartHandler = async productId => {
    setIsLoadingCart(true);
    dispatch(closeOrderModal());
    await dispatch(removeFromWishlist(productId, catalog_id));
    await dispatch(addToCart(productId, catalog_id));

    setIsLoadingCart(false);
  };
  useEffect(() => {
    if (wishlist && product) {
      const exist = wishlist.find(el => el.id === product.id);

      if (exist) {
        setInWishlist(true);
      } else {
        setInWishlist(false);
      }
    }
  }, [wishlist, product]);

  useEffect(() => {
    if (cart && product) {
      const exist = cart.find(el => el.id === product.id);
      if (exist) {
        setInCart(true);
      } else {
        setInCart(false);
      }
    }
  }, [cart, product]);
  const handlerModal = () => {
    dispatch(getSingleProduct(id, catalog_id));
    product && dispatch(openOrderModal(product));
  };
  const removeCartHandler = async () => {
    setIsLoadingCart(true);
    pathName === '/cart' && dispatch(closeOrderModal());
    await dispatch(removeFromCart(id, catalog_id));
    setIsLoadingCart(false);
  };

  const addCartHandler = async () => {
    setIsLoadingCart(true);

    if (score - points < 0) {
      setpuntiAbbastanza(true);
      setIsLoadingCart(false);
    } else {
      dispatch(addToCart(id, catalog_id)).then(res => {
        setIsLoadingCart(false);
        !Boolean(res.error) && history.push('/cart');
      });
    }
  };
  const moveToWishlistHandler = async productId => {
    setIsLoadingWish(true);
    dispatch(closeOrderModal());
    await dispatch(removeFromCart(productId, catalog_id));
    await dispatch(addToWishlist(productId, catalog_id));
    setIsLoadingWish(false);
  };

  return (
    <Container
      {...restProps}
      horiz={horiz}
      inModal={inModal}
      className='inSlider'
      products_shadow={products_shadow}
      show_border={products_shadow ? false : show_product_borders}
    >
      <Img
        horiz={horiz}
        onClick={() => {
          if (inApp) {
            window.location.replace(`/product/${id}/catalog/${catalog_id}`);
          } else {
            if (pathName !== '/auth/login') {
              setIsReviews(false);
              handlerModal();
            } else {
              return;
            }
          }
        }}
      >
        <img src={images[0]?.big} alt='' />
      </Img>

      <Content horiz={horiz} inModal={inModal} show_border={show_product_borders}>
        {!horiz && (
          <Flex justify='center'>
            <Text size={16} text_box>
              {macroCategory_description}
            </Text>

            <Text size={16} margin='0 0 0 5px' bold text_box>
              {brand_description}
            </Text>
          </Flex>
        )}
        <Text
          as='h4'
          size={14}
          align='center'
          clamp={!inModal}
          style={{ minHeight: !horiz ? '33px' : 'auto' }}
          className='textTitle'
          text_box
        >
          {title}
        </Text>
        {!horiz && (
          <Text bold as='h4' size={16} capit text_box align='center' style={{ marginTop: 'auto' }}>
            {numberWithDots(points)} {is_salabam ? 'soglia punti' : pointsLabel}{' '}
            {has_fee && ' + ' + fee}
          </Text>
        )}
        {date && (
          <Flex>
            <Text capit bold style={{ marginRight: '5px' }} text_box>
              data:
            </Text>{' '}
            <Text text_box>{moment(date).format('DD/MM/YYYY HH:MM')}</Text>
          </Flex>
        )}
        {status && (
          <Text capit text_box>
            status: {status}
          </Text>
        )}

        {!horiz && (
          <Button
            size={14}
            padding='0 10px 0 25px'
            style={{ backgroundImage: 'none' }}
            default
            border
            capit
            fullwidth
            className='descMobile'
            onClick={() => {
              if (pathName !== '/auth/login') {
                setIsReviews(false);
                handlerModal();
              } else {
                return;
              }
            }}
            disabled={inModal}
          >
            <Text
              as='span'
              upper
              type='textSlider'
              width='fit-content'
              style={{ whiteSpace: 'nowrap' }}
              className='textDesc'
              btns
              text_box
            >
              Descrizione completa
            </Text>
            <AiOutlineFileSearch size={24} />
          </Button>
        )}

        <Flex align='center' className='recentFlex'>
          <Flex justify='center' align='center' wrap='nowrap'>
            <StarRating
              num={rating}
              numRating={numRating}
              setNumRating={setNumRating}
              inSlider={inSlider}
              readonly={true}
              halfStar={true}
            />
            <Text text_box as='p' style={{ marginLeft: '5px' }}>
              ({reviews?.length}){' '}
            </Text>
          </Flex>
          {!horiz && !reviews?.find(elem => elem.player_id === userId) && (
            <Text
              as='div'
              size={12}
              upper
              bol
              text_box
              className='recentText'
              onClick={() => {
                if (pathName !== '/auth/login') {
                  setIsReviews(true);
                  !inModal && handlerModal();
                } else {
                  return;
                }
              }}
            >
              INVIA RECENSIONE
            </Text>
          )}
        </Flex>

        {!horiz && pathName !== '/user/options/ordini' && hierarchy !== 'configurable' && (
          <Flex className='flexBtns'>
            {!is_salabam && isCart && (
              <Button
                size={14}
                padding='0 10px 0 25px'
                style={{ backgroundImage: 'none' }}
                border
                upper
                loading={isLoadingCart}
                disab
                className='btns btnMobile hoverBtn'
                disabled={pathName === '/wishlist' ? false : inWishlist}
                onClick={() => {
                  if (inApp) {
                    return window.location.replace(`/addtocart/${id}/catalog/${catalog_id}`);
                  }
                  return pathName === '/catalog' || pathName === '/'
                    ? inCart
                      ? removeCartHandler()
                      : addCartHandler()
                    : pathName === '/wishlist'
                    ? moveToCartHandler(id)
                    : pathName === '/cart'
                    ? removeCartHandler(id)
                    : null;
                }}
              >
                <Text
                  as='span'
                  upper
                  type='textSlider'
                  width='fit-content'
                  style={{ whiteSpace: 'nowrap' }}
                  className='textDesc textMobile'
                  text_box
                >
                  {pathName === '/catalog' || pathName === '/' || pathName === '/auth/login'
                    ? inCart
                      ? 'Rimuovi dal CARRELLO'
                      : 'AGGIUNGI AL CARRELLO'
                    : pathName === '/wishlist'
                    ? 'Sposta nel carrello'
                    : pathName === '/cart'
                    ? 'Rimuovi dal carrello'
                    : ''}
                </Text>
                {inCart ? (
                  <RiDeleteBin2Line />
                ) : option?.menuSettings?.cart?.icon_url ? (
                  <AddToCart className='maskIcon' src={option?.menuSettings?.cart?.icon_url} />
                ) : (
                  <AiOutlineShoppingCart />
                )}
              </Button>
            )}

            {isWishlist && (
              <Button
                size={14}
                padding='0 10px 0 25px'
                style={{ backgroundImage: 'none' }}
                border
                upper
                loading={isLoadingWish}
                disabled={pathName === '/cart' ? false : inCart}
                className='btns hoverBtn'
                onClick={() =>
                  pathName === '/catalog' || pathName === '/'
                    ? inWishlist
                      ? removeFromWishlistHandler(id)
                      : addToWishlistHandler(id)
                    : pathName === '/wishlist'
                    ? removeFromWishlistHandler(id)
                    : pathName === '/cart'
                    ? moveToWishlistHandler(id)
                    : null
                }
              >
                <Text
                  as='span'
                  upper
                  type='textSlider'
                  width='fit-content'
                  style={{ whiteSpace: 'nowrap' }}
                  className='textDesc textMobile'
                  text_box
                >
                  {pathName === '/catalog' || pathName === '/' || pathName === '/auth/login'
                    ? inWishlist
                      ? 'Rimuovi dai preferiti'
                      : 'Aggiungi ai preferiti'
                    : pathName === '/wishlist'
                    ? 'Rimuovi dai preferiti'
                    : pathName === '/cart'
                    ? 'Sposta nei preferiti'
                    : ''}
                </Text>
                {inWishlist ? (
                  <RemoveWishlistIcon style={{ width: '30px' }} />
                ) : option?.menuSettings?.wishlist?.icon_url ? (
                  <AddToWishlist
                    className='maskIcon'
                    src={option?.menuSettings?.wishlist?.icon_url}
                  />
                ) : (
                  <AiOutlineHeart />
                )}
              </Button>
            )}
          </Flex>
        )}
      </Content>
    </Container>
  );
}

export default Product;
